var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2.55556 8C2.55556 4.99312 4.99312 2.55556 8 2.55556C11.0069 2.55556 13.4444 4.99312 13.4444 8C13.4444 11.0069 11.0069 13.4444 8 13.4444C4.99312 13.4444 2.55556 11.0069 2.55556 8ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM10.5055 10.5054C10.2017 10.8092 9.70925 10.8092 9.40551 10.5054L8.00003 9.09994L6.59454 10.5054C6.2908 10.8092 5.79834 10.8092 5.4946 10.5054C5.19086 10.2017 5.19086 9.70923 5.4946 9.40548L6.90008 8L5.4946 6.59452C5.19086 6.29077 5.19086 5.79831 5.4946 5.49457C5.79834 5.19083 6.2908 5.19083 6.59454 5.49457L8.00003 6.90006L9.40551 5.49457C9.70925 5.19083 10.2017 5.19083 10.5055 5.49457C10.8092 5.79831 10.8092 6.29078 10.5055 6.59452L9.09997 8L10.5055 9.40548C10.8092 9.70923 10.8092 10.2017 10.5055 10.5054Z",
          fill: "#F9375A",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }