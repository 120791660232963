<template>
  <section v-loading="loading" class="timeslot">
    <TerminalBackButton class="timeslot__backButton" @clicked="backModal" />

    <div class="timeslot__container">
      <div class="timeslot__title">Таймслот в порядке очереди</div>

      <div class="timeslot__truck">Авто:&nbsp;{{ plateTruck }}</div>

      <div class="timeslot__subtitle">
        Если номер авто некорректный - вернитесь на экран назад и введите новый
        номер.
      </div>

      <el-form
        ref="timeslotForm"
        class="timeslot__form"
        :model="timeslotForm"
        :validate-on-rule-change="false"
      >
        <div class="timeslot__form-row">
          <el-form-item
            ref="truck_type_code"
            class="item"
            prop="truck_type_code"
            :rules="rules.truck_type_code"
          >
            <div class="timeslot__select" @click="openTruckTypeSelect">
              <span
                :class="[
                  'timeslot__select-content',
                  currentTruckType ? 'value' : 'placeholder',
                ]"
              >
                {{ currentTruckType || 'Тип авто' }}
              </span>
              <InputChevronDown />
            </div>

            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />

                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>

          <el-form-item
            ref="culture_id"
            class="item"
            prop="culture_id"
            :rules="rules.culture_id"
          >
            <div class="timeslot__select" @click="openCulturesSelect">
              <span
                :class="[
                  'timeslot__select-content',
                  currentCulture ? 'value' : 'placeholder',
                ]"
              >
                {{ currentCulture || 'Культура' }}
              </span>

              <InputChevronDown />
            </div>
            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />

                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>
        </div>

        <div class="timeslot__form-row">
          <el-form-item
            ref="exporter_id"
            class="item"
            prop="exporter_id"
            :rules="rules.exporter_id"
          >
            <div class="timeslot__select" @click="openExportersSelect">
              <span
                :class="[
                  'timeslot__select-content',
                  currentExporter ? 'value' : 'placeholder',
                ]"
              >
                {{ currentExporter || 'Экспортер' }}
              </span>
              <InputChevronDown v-if="!isExporterDisabled" />
            </div>
            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />
                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>

          <el-form-item
            ref="phone"
            class="item"
            prop="phone"
            :rules="rules.phone"
          >
            <SimpleInput
              v-model="timeslotForm.phone"
              v-mask="'+7 (###) ###-##-##'"
              placeholder="Телефон водителя"
              type="tel"
              @focus="resetPhoneField"
            />
            <template #error="{ error }">
              <div class="customErrors">
                <ValidationErrorIcon />
                <div class="customErrors__text">{{ error }}</div>
              </div>
            </template>
          </el-form-item>
        </div>

        <MainButton
          class="timeslot__form-button"
          :title="hasSecondForm ? 'Далее' : 'Стать в общую очередь'"
          type="primary"
          size="big"
          @button-clicked="submit('timeslotForm')"
        />
      </el-form>
    </div>

    <div class="simpleKeyboard">
      <SimpleKeyboard
        class="simpleKeyboard__board"
        :input="timeslotForm.phone"
        @onKeyPress="onKeyPress"
        @onChange="onChange"
      />
    </div>

    <CommonQueueModal
      v-if="isModalOpen"
      title="Вы уверены, что хотите покинуть экран?"
      description="Введенные данные будут удалены"
      cancel="Отменить"
      accept="Покинуть"
      @cancel="isModalOpen = false"
      @accept="$router.push({ name: 'terminal-carNumber' })"
    />

    <SelectOptionsModal
      v-if="isTruckTypeOpen"
      title="Выбор типа авто"
      :list="preparedTruckTypeList"
      :active="timeslotForm.truck_type_code"
      @cancel="isTruckTypeOpen = false"
      @accept="truckTypeSelected"
    />

    <SelectOptionsModal
      v-if="isCulturesOpen"
      title="Выбор культуры"
      :list="preparedCultureList"
      :active="timeslotForm.culture_id"
      @cancel="isCulturesOpen = false"
      @accept="cultureSelected"
    />

    <SelectOptionsModal
      v-if="isExportersOpen"
      title="Выбор экспортера"
      :list="preparedExportersList"
      :active="timeslotForm.exporter_id"
      @cancel="isExportersOpen = false"
      @accept="exporterSelected"
    />
  </section>
</template>

<script>
import CommonQueueModal from '@/views/terminalUI/components/modals/CommonQueueModal'
import InputChevronDown from '@/assets/icons/InputChevronDown.vue'
import MainButton from '../components/UI/buttons/MainButton.vue'
import SelectOptionsModal from '@/views/terminalUI/components/modals/SelectOptionsModal'
import SimpleInput from '../components/UI/controls/SimpleInput.vue'
import SimpleKeyboard from '../components/UI/controls/SimpleKeyboard'
import TerminalBackButton from '@/views/terminalUI/components/UI/buttons/TerminalBackButton.vue'
import ValidationErrorIcon from '@/assets/icons/ValidationErrorIcon.vue'

import Api from '@/api'

import {
  FETCH_EXPORTERS_ALL,
  GET_EXPORTERS_ALL,
} from '@/views/control/store/actions'
import {
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_CURRENT_ID_SLUG,
  GET_TERMINAL_CURRENT_REQUIRE_INN,
} from '@/store/actions'
import { autoTypeRequired } from '@/constants/auto-type'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HandTimeslotForm',
  components: {
    CommonQueueModal,
    TerminalBackButton,
    MainButton,
    SelectOptionsModal,
    SimpleInput,
    SimpleKeyboard,
    ValidationErrorIcon,
    InputChevronDown,
  },
  data() {
    return {
      timeslotForm: {
        truck_type_code: '',
        culture_id: '',
        phone: '',
        exporter_id: '',
        inn: '',
      },
      rules: {
        phone: [
          {
            required: true,
            message: 'Заполните номер телефона',
            trigger: 'change',
          },
          {
            min: 18,
            max: 18,
            message: 'Номер слишком короткий',
            trigger: 'blur',
          },
        ],
        truck_type_code: [
          {
            required: true,
            message: 'Выберите тип авто',
            trigger: 'change',
          },
        ],
        culture_id: [
          {
            required: true,
            message: 'Выберите культуру',
            trigger: 'change',
          },
        ],
        exporter_id: [
          {
            required: true,
            message: 'Выберите экспортера',
            trigger: 'change',
          },
        ],
      },
      truckTypeList: [],
      currentTruckType: '',
      cultureList: [],
      currentCulture: '',
      currentExporter: '',
      isModalOpen: false,
      isTruckTypeOpen: false,
      isCulturesOpen: false,
      isExportersOpen: false,
      plateTruck: '',
      loading: false,
      isPhoneDeleting: false,
    }
  },
  computed: {
    ...mapGetters({
      terminalId: GET_TERMINAL_CURRENT_ID,
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
      terminalRequireInn: GET_TERMINAL_CURRENT_REQUIRE_INN,
      exportersList: GET_EXPORTERS_ALL,
    }),
    preparedTruckTypeList() {
      return this.truckTypeList.map(item => ({
        id: item.id,
        value: item.title,
      }))
    },
    preparedCultureList() {
      return this.cultureList.map(item => ({
        id: String(item.culture_id),
        value: this.getFullCulture(item),
      }))
    },
    preparedExportersList() {
      return this.exportersList.map(item => ({
        id: String(item.id),
        value: item.name,
      }))
    },
    hasSecondForm() {
      return this.terminalSlug === 'step' || this.terminalRequireInn
    },
    isExporterDisabled() {
      return this.exportersList.length === 1
    },
  },
  watch: {
    exportersList() {
      if (this.isExporterDisabled) {
        this.currentExporter = this.preparedExportersList[0].value
        this.timeslotForm.exporter_id = this.preparedExportersList[0].id
      }
    },
  },
  async mounted() {
    this.loading = true

    await this.fetchExporters()

    // загружаем справочники культур и типов авто
    this.truckTypeList = autoTypeRequired
    const { data } = await Api.control.culture.getActiveQuotasCultures({
      unload_id: this.terminalId,
    })

    this.cultureList = data

    // если приходим с подтверждения госномера
    this.plateTruck = this.$route.params.truck || ''

    // если возвращаемся с добавочной формы (Степь) -
    // восстанавливаем данные формы
    if (this.$route.params.form) {
      const form = this.$route.params.form

      this.timeslotForm.truck_type_code = form.truck_type_code
      this.truckTypeSelected(form.truck_type_code)
      this.timeslotForm.culture_id = form.culture_id
      this.cultureSelected(form.culture_id)
      this.timeslotForm.exporter_id = form.exporter_id
      this.exporterSelected(form.exporter_id)
      this.timeslotForm.phone = form.phone
      this.plateTruck = form.plate_truck
    }
    this.loading = false
  },
  methods: {
    ...mapActions({
      fetchExporters: FETCH_EXPORTERS_ALL,
    }),
    submit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const params = {
            unload_id: this.terminalId,
            plate_truck: this.plateTruck,
            truck_type_code: this.timeslotForm.truck_type_code,
            culture_id: this.timeslotForm.culture_id,
            exporter_id: this.timeslotForm.exporter_id,
            phone: this.timeslotForm.phone,
          }

          if (this.hasSecondForm) {
            // если Степь или нужен ИНН - уходим на заполнение второй формы
            await this.$router.push({
              name: 'terminal-additionalFieldsForm',
              params: { form: params },
            })
          } else {
            // для остальных терминалов сразу делаем запрос на ручной ТС
            this.loading = true
            try {
              await Api.timeslots.timeslotCreate(params)

              await this.$router.push({
                name: 'terminal-finallyHandResponse',
                params: {
                  phone: this.timeslotForm.phone,
                  plate_truck: this.plateTruck,
                },
              })
            } catch (e) {
              await this.$router.push({
                name: 'terminal-limitHandResponse',
                params: { hasTimeslot: false },
              })
            } finally {
              this.loading = false
            }
          }
        }
      })
    },
    resetPhoneField() {
      this.timeslotForm.phone = ''
      this.$refs.phone.clearValidate()
    },
    backModal() {
      this.isModalOpen = true
    },
    openTruckTypeSelect() {
      this.$refs.truck_type_code.clearValidate()
      this.isTruckTypeOpen = true
    },
    truckTypeSelected(type) {
      this.currentTruckType = this.truckTypeList.find(
        item => item.id === type,
      ).title
      this.timeslotForm.truck_type_code = type
      this.isTruckTypeOpen = false
    },
    openCulturesSelect() {
      this.$refs.culture_id.clearValidate()
      this.isCulturesOpen = true
    },
    cultureSelected(type) {
      this.currentCulture = this.cultureList.find(
        item => item.culture_id === Number(type),
      ).culture_name
      this.timeslotForm.culture_id = type
      this.isCulturesOpen = false
    },
    getFullCulture(culture) {
      let year = ''

      if (culture.harvest_year && culture.harvest_year.length) {
        year = ' ' + culture.harvest_year + ' года'
      }

      return culture.culture_name + year
    },
    openExportersSelect() {
      if (!this.isExporterDisabled) {
        this.$refs.exporter_id.clearValidate()
        this.isExportersOpen = true
      }
    },
    exporterSelected(type) {
      this.currentExporter = this.exportersList.find(
        item => item.id === Number(type),
      ).name
      this.timeslotForm.exporter_id = type
      this.isExportersOpen = false
    },

    // обработчики нажатий на виртуальную клавиатуру
    onKeyPress(button) {
      this.isPhoneDeleting = button === '{bksp}'
    },
    onChange(input) {
      let formatted = input

      if (formatted.length === 1 && !this.isPhoneDeleting) {
        formatted = '+7 (' + formatted
      }
      if (formatted.length === 2 && !this.isPhoneDeleting) {
        formatted = '+7 (' + formatted[1]
      }
      if (formatted.length === 3 && !this.isPhoneDeleting) {
        formatted = formatted.slice(0, 2) + ' (' + formatted[2]
      }
      if (formatted.length === 4 && !this.isPhoneDeleting) {
        formatted = formatted.slice(0, 3) + '(' + formatted[3]
      }
      if (formatted.length === 8 && !this.isPhoneDeleting) {
        formatted = formatted.slice(0, 7) + ') ' + formatted[7]
      }
      if (formatted.length === 9 && !this.isPhoneDeleting) {
        formatted = formatted.slice(0, 8) + ' ' + formatted[8]
      }
      if (formatted.length === 13 && !this.isPhoneDeleting) {
        formatted = formatted.slice(0, 12) + '-' + formatted[12]
      }
      if (formatted.length === 16 && !this.isPhoneDeleting) {
        formatted = formatted.slice(0, 15) + '-' + formatted[15]
      }
      if (formatted.length > 18) {
        formatted = formatted.slice(0, 18)
      }
      this.timeslotForm.phone = formatted
    },
  },
}
</script>

<style lang="sass" scoped>
@import "./styles"
</style>
