var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "timeslot",
    },
    [
      _c("TerminalBackButton", {
        staticClass: "timeslot__backButton",
        on: { clicked: _vm.backModal },
      }),
      _c(
        "div",
        { staticClass: "timeslot__container" },
        [
          _c("div", { staticClass: "timeslot__title" }, [
            _vm._v("Таймслот в порядке очереди"),
          ]),
          _c("div", { staticClass: "timeslot__truck" }, [
            _vm._v("Авто: " + _vm._s(_vm.plateTruck)),
          ]),
          _c("div", { staticClass: "timeslot__subtitle" }, [
            _vm._v(
              " Если номер авто некорректный - вернитесь на экран назад и введите новый номер. "
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "timeslotForm",
              staticClass: "timeslot__form",
              attrs: {
                model: _vm.timeslotForm,
                "validate-on-rule-change": false,
              },
            },
            [
              _c(
                "div",
                { staticClass: "timeslot__form-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "truck_type_code",
                      staticClass: "item",
                      attrs: {
                        prop: "truck_type_code",
                        rules: _vm.rules.truck_type_code,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "error",
                          fn: function ({ error }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "customErrors" },
                                [
                                  _c("ValidationErrorIcon"),
                                  _c(
                                    "div",
                                    { staticClass: "customErrors__text" },
                                    [_vm._v(_vm._s(error))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "timeslot__select",
                          on: { click: _vm.openTruckTypeSelect },
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "timeslot__select-content",
                                _vm.currentTruckType ? "value" : "placeholder",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentTruckType || "Тип авто") +
                                  " "
                              ),
                            ]
                          ),
                          _c("InputChevronDown"),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      ref: "culture_id",
                      staticClass: "item",
                      attrs: {
                        prop: "culture_id",
                        rules: _vm.rules.culture_id,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "error",
                          fn: function ({ error }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "customErrors" },
                                [
                                  _c("ValidationErrorIcon"),
                                  _c(
                                    "div",
                                    { staticClass: "customErrors__text" },
                                    [_vm._v(_vm._s(error))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "timeslot__select",
                          on: { click: _vm.openCulturesSelect },
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "timeslot__select-content",
                                _vm.currentCulture ? "value" : "placeholder",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentCulture || "Культура") +
                                  " "
                              ),
                            ]
                          ),
                          _c("InputChevronDown"),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "timeslot__form-row" },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "exporter_id",
                      staticClass: "item",
                      attrs: {
                        prop: "exporter_id",
                        rules: _vm.rules.exporter_id,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "error",
                          fn: function ({ error }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "customErrors" },
                                [
                                  _c("ValidationErrorIcon"),
                                  _c(
                                    "div",
                                    { staticClass: "customErrors__text" },
                                    [_vm._v(_vm._s(error))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "timeslot__select",
                          on: { click: _vm.openExportersSelect },
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "timeslot__select-content",
                                _vm.currentExporter ? "value" : "placeholder",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentExporter || "Экспортер") +
                                  " "
                              ),
                            ]
                          ),
                          !_vm.isExporterDisabled
                            ? _c("InputChevronDown")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      ref: "phone",
                      staticClass: "item",
                      attrs: { prop: "phone", rules: _vm.rules.phone },
                      scopedSlots: _vm._u([
                        {
                          key: "error",
                          fn: function ({ error }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "customErrors" },
                                [
                                  _c("ValidationErrorIcon"),
                                  _c(
                                    "div",
                                    { staticClass: "customErrors__text" },
                                    [_vm._v(_vm._s(error))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("SimpleInput", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "+7 (###) ###-##-##",
                            expression: "'+7 (###) ###-##-##'",
                          },
                        ],
                        attrs: { placeholder: "Телефон водителя", type: "tel" },
                        on: { focus: _vm.resetPhoneField },
                        model: {
                          value: _vm.timeslotForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.timeslotForm, "phone", $$v)
                          },
                          expression: "timeslotForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("MainButton", {
                staticClass: "timeslot__form-button",
                attrs: {
                  title: _vm.hasSecondForm ? "Далее" : "Стать в общую очередь",
                  type: "primary",
                  size: "big",
                },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.submit("timeslotForm")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "simpleKeyboard" },
        [
          _c("SimpleKeyboard", {
            staticClass: "simpleKeyboard__board",
            attrs: { input: _vm.timeslotForm.phone },
            on: { onKeyPress: _vm.onKeyPress, onChange: _vm.onChange },
          }),
        ],
        1
      ),
      _vm.isModalOpen
        ? _c("CommonQueueModal", {
            attrs: {
              title: "Вы уверены, что хотите покинуть экран?",
              description: "Введенные данные будут удалены",
              cancel: "Отменить",
              accept: "Покинуть",
            },
            on: {
              cancel: function ($event) {
                _vm.isModalOpen = false
              },
              accept: function ($event) {
                return _vm.$router.push({ name: "terminal-carNumber" })
              },
            },
          })
        : _vm._e(),
      _vm.isTruckTypeOpen
        ? _c("SelectOptionsModal", {
            attrs: {
              title: "Выбор типа авто",
              list: _vm.preparedTruckTypeList,
              active: _vm.timeslotForm.truck_type_code,
            },
            on: {
              cancel: function ($event) {
                _vm.isTruckTypeOpen = false
              },
              accept: _vm.truckTypeSelected,
            },
          })
        : _vm._e(),
      _vm.isCulturesOpen
        ? _c("SelectOptionsModal", {
            attrs: {
              title: "Выбор культуры",
              list: _vm.preparedCultureList,
              active: _vm.timeslotForm.culture_id,
            },
            on: {
              cancel: function ($event) {
                _vm.isCulturesOpen = false
              },
              accept: _vm.cultureSelected,
            },
          })
        : _vm._e(),
      _vm.isExportersOpen
        ? _c("SelectOptionsModal", {
            attrs: {
              title: "Выбор экспортера",
              list: _vm.preparedExportersList,
              active: _vm.timeslotForm.exporter_id,
            },
            on: {
              cancel: function ($event) {
                _vm.isExportersOpen = false
              },
              accept: _vm.exporterSelected,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }